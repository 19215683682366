import { toI18n } from 'util/i18n';

export const FEATURE_NAME = 'demo_react_schedule_builder';

export const SLICE_NAME = 'scheduleBuilderView';
export const I18N_SB_HEADER_PATH = 'schedule_builder.react_page.header';
export const I18N_SB_SHIFTS_PATH = 'schedule_builder.react_page.shifts';
export const I18N_SB_VIEW_BY_PATH =
  'schedule_builder.react_page.view_by_select';
export const I18N_SHOW_HIDE_FILTER_PATH =
  'schedule_builder.react_page.show_hide_filter';
export const MODULE_NAME = 'fe-core';

export const SEARCH_DEBOUNCE_TIME = 500;

export const LS_RANGE_START = 'features.scheduleBuilder.weekStart';
export const LS_RANGE_END = 'features.scheduleBuilder.rangeEnd';
export const LS_VIEW_TYPE = 'features.scheduleBuilder.viewType';
export const LS_RANGE_TYPE = 'features.scheduleBuilder.rangeType';

export const DEFAULT_OPTION_STATE = true;

export const VIEW_TYPES = {
  startTime: 'start_time',
  employee: 'employee',
  department: 'department',
  role: 'role',
};

export const RANGE_TYPES = {
  week: 'week',
  month: 'month',
  day: 'day',
  custom: 'custom',
};

export const SORT_BY_WEEK_OPTIONS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  CUSTOM: 'custom',
  POSITION: 'position',
};

export const VIEW_BY_DAY = 'ViewByDay';

export const EMPLOYEE_VIEW_FILTERS = {
  ALL: 'all',
  SCHEDULED: 'scheduled',
};

export const BASE_ROUTE = '/schedule';

export const ROUTES = {
  // eslint-disable-next-line max-len
  SCHEDULE_BUILDER_WEEK_VIEW_REGEX_PATH: `${BASE_ROUTE}/:viewType(employee|department|role)/:rangeType(week)/:rangeStart`,
  // eslint-disable-next-line max-len
  SCHEDULE_BUILDER_MONTH_VIEW_REGEX_PATH: `${BASE_ROUTE}/:viewType(employee)/:rangeType(month)/:rangeStart`,
  // eslint-disable-next-line max-len
  SCHEDULE_BUILDER_DAY_VIEW_REGEX_PATH: `${BASE_ROUTE}/:viewType(employee|start_time)/:rangeType(day)/:rangeStart`,
  // eslint-disable-next-line max-len
  SCHEDULE_BUILDER_CUSTOM_VIEW_REGEX_PATH: `${BASE_ROUTE}/:viewType(employee)/:rangeType(custom)/:rangeStart/:rangeEnd`,
};

export const CONSECUTIVE_SHIFTS_MAX = 5;

export const DEPARTMENT_NOT_SET = 'Dept. Not Set';

export const DRAWERS = {
  jumpstart_drawer: 'jumpstart_drawer',
  publish_drawer: 'publish_drawer',
  templates_drawer: 'templates_drawer',
  print_schedule_drawer: 'print_schedule_drawer',
  send_to_partner_drawer: 'send_to_partner_drawer',
};

export const MODALS = {
  predictive_scheduling_generation_modal:
    'predictive_scheduling_generation_modal',
};

export const REACT_SCHEDULE_BUILDER_VERSION = 'react';

export const FIRST_TIME_POPUPS = {
  COPY_MODE_EXPLAINER: 'copy_mode_explainer',
};

export const UNSELECTED_RADIO_STATE = '';

export const FILTERS = {
  IS_AVAILABILITIES_VISIBLE: 'isAvailabilitiesVisible',
  IS_EVENTS_VISIBLE: 'isEventsVisible',
  IS_HOURS_FORECAST_VISIBLE: 'isHoursForecastVisible',
  IS_PEOPLE_FORECAST_VISIBLE: 'isPeopleForecastVisible',
  IS_SCHEDULE_USERS_ONLY: 'isScheduleUsersOnly',
  IS_SHIFTS_AT_THIS_LOCATION_ONLY: 'isShiftsAtThisLocationOnly',
  IS_TIME_OFFS_VISIBLE: 'isTimeOffsVisible',
  IS_WAGE_FORECAST_VISIBLE: 'isWageForecastVisible',
};

export const MONTH_FILTERS = [
  FILTERS.IS_EVENTS_VISIBLE,
  FILTERS.IS_AVAILABILITIES_VISIBLE,
  FILTERS.IS_TIME_OFFS_VISIBLE,
];

export const WEEK_FILTERS = [
  FILTERS.IS_EVENTS_VISIBLE,
  FILTERS.IS_SHIFTS_AT_THIS_LOCATION_ONLY,
  FILTERS.IS_HOURS_FORECAST_VISIBLE,
  FILTERS.IS_WAGE_FORECAST_VISIBLE,
  FILTERS.IS_PEOPLE_FORECAST_VISIBLE,
  FILTERS.IS_SCHEDULE_USERS_ONLY,
];

export const FORECAST_FILTERS = [
  FILTERS.IS_HOURS_FORECAST_VISIBLE,
  FILTERS.IS_WAGE_FORECAST_VISIBLE,
  FILTERS.IS_PEOPLE_FORECAST_VISIBLE,
];

export const VIEW_BY = {
  firstName: 'first_name',
  lastName: 'last_name',
  custom: 'custom',
  startTime: 'start_time',
  department: VIEW_TYPES.department,
  role: VIEW_TYPES.role,
};

const SB_FORCED_MIGRATION_ONE_TIME_EVENTS = {
  REACT_WELCOME_MODAL_VIEWED: 'sb_react_welcome_modal_viewed',
};

export const SB_ONE_TIME_EVENTS = {
  RIGHT_CLICK_TOOL_TIP_SHOWN: 'sb_right_click_tooltip_shown',
  ...SB_FORCED_MIGRATION_ONE_TIME_EVENTS,
};

export const VIEW_BY_OPTIONS = {
  week: [
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.first_name`),
      value: VIEW_BY.firstName,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.last_name`),
      value: VIEW_BY.lastName,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.custom_name_order`),
      value: VIEW_BY.custom,
    },
    {
      divider: true,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.department`),
      value: VIEW_BY.department,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.role`),
      value: VIEW_BY.role,
    },
  ],
  day: [
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.first_name`),
      value: VIEW_BY.firstName,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.last_name`),
      value: VIEW_BY.lastName,
    },
    {
      divider: true,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.start_time`),
      value: VIEW_BY.startTime,
    },
  ],
};

export const EMPLOYEE_HUB_VIEW_BY_OPTIONS = {
  week: [
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.first_name`),
      value: VIEW_BY.firstName,
    },
    {
      divider: true,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.department`),
      value: VIEW_BY.department,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.role`),
      value: VIEW_BY.role,
    },
  ],
  day: [
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.first_name`),
      value: VIEW_BY.firstName,
    },
    {
      divider: true,
    },
    {
      label: toI18n(`${I18N_SB_VIEW_BY_PATH}.start_time`),
      value: VIEW_BY.startTime,
    },
  ],
};

export const FIRST_CELL_MIN_WIDTH = {
  WEEK_VIEW: 240,
};

export const COPY_WEEK_MODAL = {
  ALL_SHIFTS: 'all_shifts',
  BY_DEPARTMENT: 'by_department',
  BY_ROLE: 'by_role',
};

export const INITIAL_VALUES_COPY_WEEK = {
  start_date: '',
  end_date: '',
  target_start_date: '',
  target_end_date: '',
  includeShifts: COPY_WEEK_MODAL.ALL_SHIFTS,
  department_ids: [],
  role_ids: [],
  includes_shifts_without_roles: true,
};

export const BUTTON_TEXT = {
  APPLY: 'Apply',
  COPY_WEEK: 'Copy week',
};

export const COPY_WEEK_DIRECTION = {
  COPY_FROM: 'copy_from',
};
